import React, { Fragment, useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import queryString from "query-string";
import agent from "../../app/api/agent";
import { toast } from "react-toastify";

const VerifyEmail: React.FC<RouteComponentProps> = ({ location }) => {
  const Status = {
    Veryfying: "Veryfying",
    Failed: "Failed",
    Success: "Success",
  };

  const [status, setStatus] = useState(Status.Veryfying);
  const { token, email } = queryString.parse(location.search);

  useEffect(() => {
    agent.User.verifyEmail(token as string, email as string)
      .then(() => {
        setStatus(Status.Success);
      })
      .catch(() => {
        setStatus(Status.Failed);
      });
  }, [Status.Failed, Status.Success, token, email]);

  const handleConfirmEmailResend = () => {
    agent.User.resendEmailVerification(email as string)
      .then(() => {
        toast.success("Verification email resent - please check your email");
      })
      .catch((error) => console.log(error));
  };

  const getBody = () => {
    switch (status) {
      case Status.Veryfying:
        return <p>Veryfying...</p>;
      case Status.Failed:
        return (
          <Fragment>
            <p className="lead g-px-100--md g-mb-20">
              Verification failed - you can try resending the verification email
            </p>
            <button
              className="btn btn-md u-btn-teal rounded-0"
              onClick={handleConfirmEmailResend}
            >
              Resend email
            </button>
          </Fragment>
        );
      case Status.Success:
        return (
          <Fragment>
            <p className="lead g-px-100--md g-mb-20">
              Email has been verified - you can now login
            </p>
            <Link
              className="btn btn-md u-btn-teal rounded-0"
              to="/login"
            >
              Go to Login
            </Link>
          </Fragment>
        );
    }
  };
  return (
    <Fragment>
      <section className="g-min-height-100vh g-flex-centered g-bg-lightblue-radialgradient-circle">
        <div className="container g-py-100">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-lg-8">
              <div className="u-shadow-v24 g-bg-white rounded g-py-40 g-px-30">
                <header className="text-center mb-4">
                  <h2 className="h2 text-uppercase g-font-weight-300">
                    Email verification
                  </h2>
                  {getBody()}
                </header>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default VerifyEmail;
