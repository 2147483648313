import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { IActivity } from "../../../app/models/activity";
import { format } from "date-fns";

const ActivityInfo: React.FC<{ activity: IActivity }> = ({ activity }) => {
  return (
    <Fragment>
      <div id="shortcode1">
        <div className="shortcode-html">
          <div className="row">
            <div className="col-lg-12 u-icon-block--hover g-mb-40">
            <div className="media g-mb-15">
                <div className="d-flex align-self-center mr-3">
                  <span className="u-icon-v2 g-color-gray-dark-v4 rounded-circle">
                    <i className="icon-education-008 u-line-icon-pro"></i>
                  </span>
                </div>
                <div className="media-body align-self-center">
                  <h3 className="h5 g-color-black mb-0">{activity.description}</h3>
                </div>
              </div>
              <div className="media g-mb-15">
                <div className="d-flex align-self-center mr-3">
                  <span className="u-icon-v2 g-color-gray-dark-v4 rounded-circle">
                    <i className="icon-education-195 u-line-icon-pro"></i>
                  </span>
                </div>
                <div className="media-body align-self-center">
                  <h3 className="h5 g-color-black mb-0">{format(activity.date, 'eeee do MMMM')} at {format(activity.date, 'h:mm a')}
                  </h3>
                </div>
              </div>
              <div className="media g-mb-15">
                <div className="d-flex align-self-center mr-3">
                  <span className="u-icon-v2 g-color-gray-dark-v4 rounded-circle">
                    <i className="icon-real-estate-020 u-line-icon-pro"></i>
                  </span>
                </div>
                <div className="media-body align-self-center">
                  <h3 className="h5 g-color-black mb-0">{activity.venue}, {activity.city}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default observer(ActivityInfo);
