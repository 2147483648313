import React, { Fragment } from "react";
import { FieldRenderProps } from "react-final-form";

interface IProps extends FieldRenderProps<string, HTMLElement> {}

const SelectInput: React.FC<IProps> = ({
  header,
  input,
  options,
  className,
  errorClassName,
  meta: { touched, error },
}) => {
  return (
    <Fragment>
      <div
        className={
          touched && error
            ? "form-group g-mb-25 u-has-error-v1"
            : "form-group g-mb-25"
        }
      >
        <label>{header}</label>
        <select {...input} className={className}>
          {options.map((o: any, i: any) => (
            <option key={i} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
        {touched && error && <small className={errorClassName}>{error}</small>}
      </div>
    </Fragment>
  );
};

export default SelectInput;
