import React, { Fragment } from "react";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <Fragment>
      <div className="g-min-height-100vh g-flex-centered g-pa-15">
        <div className="text-center g-flex-centered-item g-position-rel g-pb-15">
          <div className="g-font-size-180 g-font-size-240--sm g-line-height-1 g-font-weight-600er g-color-gray-light-v4">
            404
          </div>

          <div className="g-absolute-centered">
            <h1 className="g-color-black g-mt-minus-8 mb-0">404 Not Found</h1>

            <p className="g-font-size-18 mb-0">
              Oops! We have looked everywhere but we couldn't find this.
            </p>

            <hr className="g-brd-gray-light-v3 g-my-15" />

            <p className="g-font-size-18 mb-0">
                Here is a link to the {" "}
              <Link
                to="/activities"
                className="g-color-black g-color-primary--hover g-text-no-underline--hover"
              >
                ← Activities
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
