import React, { Fragment, useContext } from "react";
import { observer } from "mobx-react-lite";
import ActivityListItem from "./ActivityListItem";
import { RootStoreContext } from "../../../app/stores/rootStore";

export const ActivityList: React.FC = () => {

  const rootStore = useContext(RootStoreContext);
  const { activitiesByDate } = rootStore.activityStore;

  return (
    <Fragment>
      {activitiesByDate.map(([group, activities]) => (
        <Fragment key={group}>
          <div className="g-mt-10">
            
            {/* <h2 className="h4">
              <span className="g-color-primary g-ml-5">{group}</span>
            </h2> */}
          </div>
          {activities.map((activity) => (
            <ActivityListItem key={activity.id} activity={activity} />
          ))}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default observer(ActivityList);
