import axios, { AxiosResponse } from "axios";
import { IActivitiesEnvelope, IActivity } from "../models/activity";
import { history } from "../..";
import { toast } from "react-toastify";
import config from '../../config'
import { IUser, IUserFormValues } from "../models/user";
import { IPhoto, IProfile } from "../models/profile";

if(process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = 'http://localhost:5000/api';
} else {
    axios.defaults.baseURL = config.apiUrl;
}

console.log(config.apiUrl);
console.log(process.env.NODE_ENV);

axios.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('jwt');
    if(token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
})

axios.interceptors.response.use(undefined, error => {
    if(error.message === 'Network Error' && error.response === undefined) {
        toast.error('Network error - make sure the API is running!');
    }
    const {status, data, config, headers} = error.response;
    if(status === 404) {
        history.push('/notfound');
    }
    if(status === 401 && headers['www-authenticate'].includes('The token expired')) {
        window.localStorage.removeItem('jwt');
        history.push('/');
        toast.info('Your login session has expired, please login again!')
    }
    if(status === 400 && config.method === 'get' && data.errors.hasOwnProperty('id')) {
        history.push('/notfound');
    }
    if(status === 500) {
        toast.error('Server error - check the terminal for more info!')
    }
    throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    del: (url: string) => axios.delete(url).then(responseBody),
    postForm: (url: string, file: Blob) => {
        let formData = new FormData();
        formData.append('File', file);
        return axios.post(url, formData, {
            headers: {'Content-type': 'multipart/form-data'}
        }).then(responseBody)
    }
}

const Activities = {
    list: (params: URLSearchParams): Promise<IActivitiesEnvelope> => 
        axios.get('/activities', {params: params}).then(responseBody),
    details: (id: string) => requests.get(`/activities/${id}`),
    create: (activity: IActivity) => requests.post('/activities/', activity),
    update: (activity: IActivity) => requests.put(`/activities/${activity.id}`, activity),
    delete: (id: string) => requests.del(`/activities/${id}`),
    attend: (id: string) => requests.post(`/activities/${id}/attend`, {}),
    unattend: (id: string) => requests.del(`/activities/${id}/attend`)
}

const User = {
    current: (): Promise<IUser> => requests.get('/user'),
    login: (user: IUserFormValues): Promise<IUser> => requests.post(`/user/login`, user),
    register: (user: IUserFormValues): Promise<IUser> => requests.post(`/user/register`, user),
    fbLogin: (accessToken: string) => requests.post(`/user/facebook`, {accessToken}),
    refreshToken: (): Promise<IUser> => requests.post(`/user/refreshToken`, {}),
    verifyEmail: (token: string, email: string) : Promise<void> => requests.post(`/user/verifyEmail`, {token, email}),
    resendEmailVerification: (email: string) : Promise<void> => requests.get(`/user/resendEmailVerification?email=${email}`),
    forgotPassword: (email: string) : Promise<void> => requests.get(`/user/forgotPassword?email=${email}`),
    resetPassword: (token: string, email: string, password: string): Promise<void> => requests.post(`/user/resetPassword`, {token, email, password}),
}

const Profiles = {
    get: (username: string): Promise<IProfile> => requests.get(`/profiles/${username}`),
    // uploadPhoto: (photo: Blob): Promise<IPhoto> => requests.postForm('/photos/cloudinary/upload', photo), // Cloudinary
    // deletePhoto: (id: string) => requests.del(`/photos/cloudinary/delete/${id}`), // Cloudinary
    uploadPhoto: (photo: Blob): Promise<IPhoto> => requests.postForm('/photos/azure-blob/upload', photo), // Azure Blob storage
    deletePhoto: (id: string) => requests.del(`/photos/azure-blob/delete/${id}`), // Azure Blob storage
    setMainPhoto: (id: string) => requests.post(`/photos/${id}/setMain`, {}),
    updateProfile: (profile: Partial<IProfile>) => requests.put(`/profiles`, profile),
    follow: (username: string) => requests.post(`/profiles/${username}/follow`, {}),
    unfollow: (username: string) => requests.del(`/profiles/${username}/follow`),
    listFollowings: (username: string, predicate: string) => requests.get(`/profiles/${username}/following?predicate=${predicate}`),
    listFollowers: (username: string, predicate: string) => requests.get(`/profiles/${username}/follower?predicate=${predicate}`),
    listActivities: (username: string, predicate: string) => requests.get(`/profiles/${username}/activities?predicate=${predicate}`)
}

const agent = {
    Activities,
    User,
    Profiles
  };

export default agent;
