import { FORM_ERROR } from "final-form";
import React, { Fragment, useContext } from "react";
import { Spinner } from "react-bootstrap";
import { Form as FinalForm, Field } from "react-final-form";
import TextInput from "../../app/common/form/TextInput";
import { IUserFormValues } from "../../app/models/user";
import { RootStoreContext } from "../../app/stores/rootStore";
import { combineValidators, isRequired } from "revalidate";
import ErrorMessage from "../../app/common/form/ErrorMessage";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

const validate = combineValidators({
  email: isRequired("Email"),
});

const ForgotPassword = () => {
  const rootStore = useContext(RootStoreContext);
  const { forgotPassword, loading } = rootStore.userStore;
  return (
    <Fragment>
      <section className="g-min-height-100vh g-flex-centered g-bg-lightblue-radialgradient-circle">
        <div className="container g-py-100">
          <div className="row justify-content-center">
            <div className="col-sm-8 col-lg-5">
              <div className="u-shadow-v24 g-bg-white rounded g-py-40 g-px-30">
                <header className="text-center mb-4">
                  <h2 className="h2 g-color-black g-font-weight-600">
                    Forgot Password
                  </h2>
                </header>
                <FinalForm
                  onSubmit={(values: IUserFormValues) =>
                    forgotPassword(values).catch((error) => ({
                      [FORM_ERROR]: error,
                    }))
                  }
                  validate={validate}
                  render={({
                    handleSubmit,
                    submitting,
                    submitError,
                    invalid,
                    pristine,
                    dirtySinceLastSubmit,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className="g-py-15"
                      autoComplete="off"
                    >
                      <div className="row justify-content-between">
                        <div className="col-12 align-self-center text-left">
                          {submitError && !dirtySinceLastSubmit && (
                            <ErrorMessage
                              error={submitError}
                              text="Invalid username or password"
                            />
                          )}
                        </div>
                      </div>
                      <Field
                        component={TextInput}
                        header=""
                        inputType="text"
                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--hover rounded g-py-15 g-px-15"
                        errorClassName="form-control-feedback"
                        placeholder="Enter your email"
                        name="email"
                      />
                      <div className="row justify-content-between">
                        <div className="col-12">
                          <button
                            className={
                              (invalid && !dirtySinceLastSubmit) || pristine
                                ? "btn btn-block u-btn-darkgray rounded g-py-13 g-px-25"
                                : "btn btn-block u-btn-teal rounded g-py-13 g-px-25"
                            }
                            disabled={
                              (invalid && !dirtySinceLastSubmit) || pristine
                            }
                          >
                            {loading && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              ></Spinner>
                            )}
                            {!loading && <span>Submit</span>}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default observer(ForgotPassword);
