import { FORM_ERROR } from "final-form";
import React, { Fragment, useContext } from "react";
import { Spinner } from "react-bootstrap";
import { Form as FinalForm, Field } from "react-final-form";
import TextInput from "../../app/common/form/TextInput";
import { IUserFormValues } from "../../app/models/user";
import { RootStoreContext } from "../../app/stores/rootStore";
import { combineValidators, isRequired } from "revalidate";
import ErrorMessage from "../../app/common/form/ErrorMessage";
import SocialLogin from "./SocialLogin";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

const validate = combineValidators({
  email: isRequired("Email"),
  password: isRequired("Password"),
});

const LoginForm = () => {
  const rootStore = useContext(RootStoreContext);
  const { login, fbLogin, loading } = rootStore.userStore;
  return (
    <Fragment>
      <section className="g-min-height-100vh g-flex-centered g-bg-lightblue-radialgradient-circle">
        <div className="container g-py-100">
          <div className="row justify-content-center">
            <div className="col-sm-8 col-lg-5">
              <div className="u-shadow-v24 g-bg-white rounded g-py-40 g-px-30">
                <header className="text-center mb-4">
                  <h2 className="h2 g-color-black g-font-weight-600">Login</h2>
                </header>
                <FinalForm
                  onSubmit={(values: IUserFormValues) =>
                    login(values).catch((error) => ({
                      [FORM_ERROR]: error,
                    }))
                  }
                  validate={validate}
                  render={({
                    handleSubmit,
                    submitting,
                    submitError,
                    invalid,
                    pristine,
                    dirtySinceLastSubmit,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className="g-py-15"
                      autoComplete="off"
                    >
                      <div className="row justify-content-between">
                        <div className="col-12 align-self-center text-left">
                          {submitError && !dirtySinceLastSubmit && (
                            <ErrorMessage
                              error={submitError}
                              text="Invalid username or password"
                            />
                          )}
                        </div>
                      </div>
                      <Field
                        component={TextInput}
                        header=""
                        inputType="text"
                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--hover rounded g-py-15 g-px-15"
                        errorClassName="form-control-feedback"
                        placeholder="Enter your email"
                        name="email"
                      />
                      <Field
                        component={TextInput}
                        header=""
                        inputType="password"
                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--hover rounded g-py-15 g-px-15"
                        errorClassName="form-control-feedback"
                        placeholder="Enter your password"
                        name="password"
                      />
                      <div className="row justify-content-between">
                        <div className="col-12">
                          <button
                            className={
                              (invalid && !dirtySinceLastSubmit) || pristine
                                ? "btn btn-block u-btn-darkgray rounded g-py-13 g-px-25"
                                : "btn btn-block u-btn-teal rounded g-py-13 g-px-25"
                            }
                            disabled={
                              (invalid && !dirtySinceLastSubmit) || pristine
                            }
                          >
                            {submitting && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              ></Spinner>
                            )}
                            {!submitting && <span>Login</span>}
                          </button>
                          <div className="row justify-content-between">
                            <div className="col align-self-center text-right">
                              <Link
                                to="forgotPassword"
                                className="g-font-size-12"
                              >
                                Forgot password?
                              </Link>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center text-center g-mb-30 g-mt-30">
                            <div className="d-inline-block align-self-center g-width-50 g-height-1 g-bg-gray-light-v1"></div>
                            <span className="align-self-center g-color-gray-dark-v5 mx-4">
                              OR
                            </span>
                            <div className="d-inline-block align-self-center g-width-50 g-height-1 g-bg-gray-light-v1"></div>
                          </div>
                          <div className="row no-gutters g-mb-40">
                            <div className="col-6">
                              <SocialLogin
                                fbCallback={fbLogin}
                                loading={loading}
                              />
                            </div>
                            <div className="col-6">
                              <button
                                className="btn btn-block u-btn-twitter rounded g-px-30 g-py-13 ml-1"
                                type="button"
                              >
                                Twitter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <pre>{JSON.stringify(form.getState(), null, 2)}</pre> */}
                    </form>
                  )}
                />
                <footer className="text-center">
                  <p className="g-color-gray-dark-v5 g-font-size-13 mb-0">
                    Don't have an account?{" "}
                    <a className="g-font-weight-600" href="/register">
                      register
                    </a>
                  </p>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default observer(LoginForm);
