import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import ActivityList from "./ActivityList";
import { LoadingComponent } from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import InfiniteScroll from "react-infinite-scroller";
import ActivityFilters from "./ActivityFilters";
import ActivityListItemContentLoader from "./ActivityListItemContentLoader";

const ActivityDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadActivities,
    loadingInitial,
    setPage,
    page,
    totalPages,
  } = rootStore.activityStore;
  const [loadingNext, setLoadingNext] = useState(false);

  const handleGetNext = () => {
    setLoadingNext(true);
    setPage(page + 1);
    loadActivities().then(() => setLoadingNext(false));
  };

  useEffect(() => {
    loadActivities();
  }, [loadActivities]);

  return (
    <Fragment>
      <div className="row">
        <ActivityFilters />
        <div className="col-md-12 g-mb-30">
          {loadingInitial && page === 0 ? (
            <ActivityListItemContentLoader />
          ) : (
            <Fragment>
              <InfiniteScroll
                pageStart={0}
                loadMore={handleGetNext}
                hasMore={!loadingNext && page + 1 < totalPages}
                initialLoad={false}
              >
                <ActivityList />
              </InfiniteScroll>
              {loadingNext && <LoadingComponent />}
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default observer(ActivityDashboard);
