import React, { Fragment } from "react";
import { IAttendee } from "../../../app/models/activity";

interface IProps {
  attendees: IAttendee[];
}

const ActivityListItemAttendees: React.FC<IProps> = ({ attendees }) => {
  return (
    <Fragment>
      {attendees.map((attendee) => (
        <div key={attendee.username} className="d-md-table-cell g-px-6 g-py-6">
          <figure className="text-center">
            <div className="mx-auto">
              <span className="d-inline-block g-pos-rel g-mr-10">
                <span className={attendee.following ? "u-badge-v2--xs g-mt-7 g-mr-7" : ""}></span>
                <img
                  className="media-object g-width-30 g-height-30 rounded-circle"
                  src={attendee.image || "/assets/images/user.png"}
                  alt="User Description"
                />
              </span>
              <p className="g-font-size-12">{attendee.displayName}</p>
            </div>
          </figure>
        </div>
      ))}
    </Fragment>
  );
};

export default ActivityListItemAttendees;
