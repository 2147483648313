import React, {
  useState,
  Fragment,
  useContext,
  useEffect,
} from "react";
import { Spinner } from "react-bootstrap";
import { ActivityFormValues } from "../../../app/models/activity";
import { v4 as uuid } from "uuid";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { Form as FinalForm, Field } from "react-final-form";
import TextInput from "../../../app/common/form/TextInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import SelectInput from "../../../app/common/form/SelectInput";
import { categories } from "../../../app/common/options/categoryOptions";
import DateInput from "../../../app/common/form/DateInput";
import { combineDateAndTime } from "../../../app/common/util/util";
import { LoadingComponent } from "../../../app/layout/LoadingComponent";
import {
  combineValidators,
  isRequired,
  composeValidators,
  hasLengthGreaterThan,
} from "revalidate";
import { RootStoreContext } from "../../../app/stores/rootStore";

const validate = combineValidators({
  title: isRequired({ message: "The title is required" }),
  category: isRequired("Category"),
  description: composeValidators(
    isRequired("Description"),
    hasLengthGreaterThan(4)({
      message: "Description needs to be at least 5 characters",
    })
  )(),
  city: isRequired("City"),
  venue: isRequired("Venue"),
  date: isRequired("Date"),
  time: isRequired("Time"),
});

interface DetailParams {
  id: string;
}

const ActivityForm: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history,
}) => {

  const rootStore = useContext(RootStoreContext);
  const {
    createActivity,
    editActivity,
    submitting,
    loadActivity
  } = rootStore.activityStore;

  const [activity, setActivity] = useState(new ActivityFormValues());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (match.params.id) {
      setLoading(true);
      loadActivity(match.params.id)
        .then((activity) => setActivity(new ActivityFormValues(activity)))
        .finally(() => setLoading(false));
    }
  }, [loadActivity, match.params.id]);

  const handleFinalFormSubmit = (values: any) => {
    console.log(values);

    const dateAndTime = combineDateAndTime(values.date, values.time);
    const { date, time, ...activity } = values;
    activity.date = dateAndTime;

    if (!activity.id) {
      let newActivity = {
        ...activity,
        id: uuid(),
      };
      createActivity(newActivity);
    } else {
      editActivity(activity);
    }

    //event.preventDefault();
  };

  if (loading) return <LoadingComponent />;

  return (
    <Fragment>
      <div className="row g-mb-30">
        <div className="col-md-8">
          <FinalForm
            validate={validate}
            initialValues={activity}
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid, pristine }) => (
              <form
                onSubmit={handleSubmit}
                className="g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-30"
              >
                <Field
                  component={TextInput}
                  header="Title"
                  inputType="text"
                  className="form-control rounded-0 form-control-md"
                  errorClassName="form-control-feedback"
                  placeholder="Enter title"
                  name="title"
                  value={activity.title}
                />
                <Field
                  component={TextAreaInput}
                  header="Description"
                  className="form-control rounded-0 form-control-md"
                  errorClassName="form-control-feedback"
                  placeholder="Enter Description"
                  rows="3"
                  name="description"
                  value={activity.description}
                />
                <Field
                  component={SelectInput}
                  header="Category"
                  name="category"
                  options={categories}
                  value={activity.category}
                  className="form-control rounded-0 form-control-md"
                  errorClassName="form-control-feedback"
                />
                <div className="form-group g-mb-25">
                  <label className="g-mb-10">Select date and time</label>
                  <div className="row">
                    <div className="col-xl-6 g-mb-40 g-mb-0--xl">
                      <Field
                        component={DateInput}
                        className="form-control rounded-0 form-control-md"
                        placeholder="Enter date"
                        name="date"
                        date={true}
                        value={activity.date}
                      />
                    </div>
                    <div className="col-xl-6">
                      <Field
                        component={DateInput}
                        className="form-control rounded-0 form-control-md"
                        placeholder="Enter time"
                        name="time"
                        time={true}
                        value={activity.time}
                      />
                    </div>
                  </div>
                </div>
                <Field
                  component={TextInput}
                  header="City"
                  inputType="text"
                  className="form-control rounded-0 form-control-md"
                  errorClassName="form-control-feedback"
                  placeholder="Enter city"
                  name="city"
                  value={activity.city}
                />
                <Field
                  component={TextInput}
                  header="Venue"
                  inputType="text"
                  className="form-control rounded-0 form-control-md"
                  errorClassName="form-control-feedback"
                  placeholder="Enter venue"
                  name="venue"
                  value={activity.venue}
                />
                <button
                  name={activity.id}
                  className="btn btn-md u-btn-teal g-mr-10 g-mb-15"
                  disabled={submitting || invalid || pristine}
                >
                  {submitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  )}
                  {!submitting && <span>Submit</span>}
                </button>
                <button
                  className="btn btn-md u-btn-darkgray g-mr-10 g-mb-15"
                  onClick={
                    activity.id
                      ? () => history.push(`/activities/${activity.id}`)
                      : () => history.push("/activities")
                  }
                  disabled={loading}
                >
                  Cancel
                </button>
              </form>
            )}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default observer(ActivityForm);
