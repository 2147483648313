import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import ForgotPasswordConfirmation from "./ForgotPasswordConfirmation";

const ResetPasswordConfirmation = () => {
  return (
    <Fragment>
      <section className="g-min-height-100vh g-flex-centered g-bg-lightblue-radialgradient-circle">
        <div className="container g-py-100">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-lg-8">
              <div className="u-shadow-v24 g-bg-white rounded g-py-40 g-px-30">
                <header className="text-center mb-4">
                  <h2 className="h2 text-uppercase g-font-weight-300">
                    Reset password confirmation!
                  </h2>
                </header>
                <p className="lead g-px-100--sm g-mb-20">
                  Your password has been reset, please <a className="g-font-weight-600" href="/login">click here to login</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ResetPasswordConfirmation;