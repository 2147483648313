import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import PhotoWidgetCropper from "./PhotoWidgetCropper";
import PhotoWidgetDropzone from "./PhotoWidgetDropzone";

interface IProps {
  loading: boolean;
  uploadPhoto: (file: Blob) => void;
}

const PhotoUploadWidget: React.FC<IProps> = ({ loading, uploadPhoto }) => {
  const [files, setFiles] = useState<any[]>([]);
  const [image, setImage] = useState<Blob | null>(null);

  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-4 u-icon-block--hover g-mb-40">
          <div className="media g-mb-15">
            <div className="media-body align-self-center">
              <h3 className="h5 g-color-black mb-0">Step 1 - Add Photo</h3>
              <PhotoWidgetDropzone setFiles={setFiles} />
            </div>
          </div>
        </div>
        <div className="col-lg-4 u-icon-block--hover g-mb-40">
          <div className="media g-mb-15">
            <div className="media-body align-self-center">
              <h3 className="h5 g-color-black mb-0">Step 2 - Resize Image</h3>
              {files.length > 0 && (
                <PhotoWidgetCropper
                  setImage={setImage}
                  imagePreview={files[0].preview}
                />
              )}
            </div>
          </div>
        </div>

        <div className="col-lg-4 u-icon-block--hover g-mb-40">
          <div className="media g-mb-15">
            <div className="media-body align-self-center">
              <h3 className="h5 g-color-black mb-0">
                Step 3 - Preview & Upload
              </h3>
              {files.length > 0 && (
                <article className="g-brd-around g-brd-gray-light-v4 text-center">
                  <div
                    className="g-brd-around g-brd-gray-light-v4 img-preview"
                    style={{ minHeight: "200px", overflow: "hidden" }}
                  />
                  <hr className="g-brd-gray-light-v4 g-my-0" />

                  <ul className="row list-inline g-py-20 g-ma-0">
                    <li className="col g-brd-right g-brd-gray-light-v4">
                      <button
                        className="btn btn-md btn-block u-btn-teal g-mr-10 g-mb-15"
                        onClick={() => uploadPhoto(image!)}
                      >
                        {loading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          ></Spinner>
                        )}
                        {!loading && <span>Upload</span>}
                      </button>
                    </li>
                    <li className="col g-brd-gray-light-v4">
                      <button
                        className="btn btn-md btn-block u-btn-darkgray g-mr-10 g-mb-15"
                        onClick={() => setFiles([])}
                        disabled={loading}
                      >
                        Cancel
                      </button>
                    </li>
                  </ul>
                </article>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default observer(PhotoUploadWidget);
