import React, { Fragment, useContext, useEffect } from "react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { Form as FinalForm, Field } from "react-final-form";
import { Link } from "react-router-dom";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import { Spinner } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { combineValidators, isRequired } from "revalidate";
import { formatDistance, parseISO } from "date-fns";

const validate = combineValidators({
  body: isRequired({ message: "A message is required" }),
});

const ActivityChat = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    createHubConnection,
    stopHubConnection,
    addComment,
    activity,
  } = rootStore.activityStore;

  useEffect(() => {
    createHubConnection(activity!.id);
    return () => {
      stopHubConnection();
    };
  }, [createHubConnection, stopHubConnection, addComment, activity]);

  return (
    <Fragment>
      <div className="g-mb-50">
        <h4 className="h4 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          Chat about this event
        </h4>
      </div>
      {activity &&
        activity.comments &&
        activity.comments.map((comment) => (
          <div className="media g-mb-30" key={comment.id}>
            <img
              className="d-flex g-width-50 g-height-50 rounded-circle g-mt-3 g-mr-20"
              src={comment.image || "/assets/images/user.png"}
              alt="User"
            />
            <div className="media-body u-shadow-v22 g-bg-secondary g-pa-30">
              <div className="g-mb-15">
                <h5 className="d-flex justify-content-between align-items-center h5 g-color-gray-dark-v1 mb-0">
                  <span className="d-block g-mr-10">{comment.displayName}</span>
                  <Link
                    to={`/profile/${comment.username}`}
                    className="u-tags-v1 g-font-size-12 g-brd-around g-brd-gray-light-v4 g-bg-primary--hover g-brd-primary--hover g-color-black-opacity-0_8 g-color-white--hover rounded g-py-6 g-px-15"
                    href="/"
                  >
                    Author
                  </Link>
                </h5>
                <span className="g-color-gray-dark-v4 g-font-size-12">
                  {formatDistance(parseISO(comment.createdAt.toString()), new Date())} { " ago." }
                </span>
              </div>

              <p>{comment.body}</p>

              <ul className="list-inline d-sm-flex my-0">
                <li className="list-inline-item g-mr-20">
                  <a
                    className="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover"
                    href="/"
                  >
                    <i className="icon-like g-pos-rel g-top-1 g-mr-3"></i>
                    214
                  </a>
                </li>
                <li className="list-inline-item g-mr-20">
                  <a
                    className="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover"
                    href="/"
                  >
                    <i className="icon-dislike g-pos-rel g-top-1 g-mr-3"></i>
                    18
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ))}
      <FinalForm
        validate={validate}
        onSubmit={addComment}
        render={({ handleSubmit, submitting, form }) => (
          <form
            onSubmit={(event) => {
              const promise = handleSubmit(event);
              promise &&
                promise.then(() => {
                  form.reset();
                  form.resetFieldState("body");
                });
              //event.preventDefault();
              return promise;
            }}
            className="g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-30"
          >
            <Field
              component={TextAreaInput}
              // header="Comment"
              className="form-control rounded-0 form-control-md"
              errorClassName="form-control-feedback"
              placeholder="Your message..."
              rows="5"
              name="body"
            />

            <button
              type="submit"
              className="btn btn-md u-btn-teal g-mr-10 g-mb-15"
            >
              {submitting && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              )}
              {!submitting && <span>Add Reply</span>}
            </button>
          </form>
        )}
      />
    </Fragment>
  );
};

export default observer(ActivityChat);
