import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { IActivity } from "../../../app/models/activity";
import ActivityListItemAttendees from "./ActivityListItemAttendees";

const ActivityListItem: React.FC<{ activity: IActivity }> = ({ activity }) => {
  const host = activity.attendees.filter((x) => x.isHost)[0];

  return (
    <div id="shortcode01">
      <div className="shortcode-html">
        <article className="d-md-table w-100 g-bg-white g-mb-1">
          <div className="d-md-table-cell align-middle g-width-125--md text-center g-color-gray-dark-v5 g-py-10 g-px-20">
            <time>
              <span className="d-block g-color-black g-font-weight-700 g-font-size-40 g-line-height-1">
                {format(activity.date, "d")}
              </span>
              {format(activity.date, "MMM")}, {format(activity.date, "yyyy")}
            </time>
          </div>
          <div className="d-md-table-cell align-middle g-width-130">
            <img
              className="d-block info-v5-2__image g-ml-minus-1"
              src={"assets/images/categoryImages/" + activity.category + ".jpg"}
              alt="Category"
              style={{ height: 120 }}
            />
          </div>
          <div className="d-md-table-cell align-middle g-py-15 g-px-20">
            <h3 className="h6 g-font-weight-700 text-uppercase">
              <div className="g-color-gray-dark-v2">
                {activity.title}
              </div>
            </h3>
            <em className="g-color-gray-dark-v5 g-font-style-normal">
              Hosted by {host.displayName} in {activity.city} at{" "}
              {activity.venue}
              {activity.isHost && (
                  <span className="u-label g-bg-bluegray g-rounded-20 g-px-15 g-ml-20">
                    You are the host
                  </span>
                )}
                {activity.isGoing && !activity.isHost && (
                  <span className="u-label g-bg-teal g-rounded-20 g-px-15 g-ml-20">
                    You are going!
                  </span>
                )}
            </em>
            <span className="d-block g-color-gray-dark-v5">
              <ActivityListItemAttendees attendees={activity.attendees} />
            </span>
          </div>
          <div className="d-md-table-cell align-middle text-md-right g-pa-20">
            <div className="g-mt-minus-10 g-mx-minus-5">
              <Link
                className="btn btn-md u-btn-outline-teal g-mr-10 g-mb-1"
                to={`/activities/${activity.id}`}
              >
                View Details
              </Link>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default observer(ActivityListItem);
