import React, { Fragment } from "react";
import { RouteComponentProps } from "react-router-dom";
import queryString from "query-string";
import agent from "../../app/api/agent";
import { toast } from "react-toastify";

const RegisterSuccess: React.FC<RouteComponentProps> = ({ location }) => {
  const { email } = queryString.parse(location.search);

  const handleConfirmEmailResend = () => {
    agent.User.resendEmailVerification(email as string)
      .then(() => {
        toast.success("Verification email resent - please check your email");
      })
      .catch((error) => console.log(error));
  };

  return (
    <Fragment>
      <section className="g-min-height-100vh g-flex-centered g-bg-lightblue-radialgradient-circle">
        <div className="container g-py-100">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-lg-8">
              <div className="u-shadow-v24 g-bg-white rounded g-py-40 g-px-30">
                <header className="text-center mb-4">
                  <h2 className="h2 text-uppercase g-font-weight-300">
                    Successfully registered!
                  </h2>
                </header>
                <p className="lead g-px-100--sm g-mb-20">
                  Please check your email (including junk folder) for the
                  verification email.
                </p>
                <p className="lead g-px-100--sm g-mb-20">
                  Didn't receive the email? Please click the button below to
                  resend.
                </p>
                <p className="text-center mb-4">
                  <button
                    className="btn btn-md u-btn-teal rounded-0"
                    onClick={handleConfirmEmailResend}
                  >
                    Resend email
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default RegisterSuccess;
