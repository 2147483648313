import React, { Fragment } from "react";

const ForgotPasswordConfirmation = () => {
  return (
    <Fragment>
      <section className="g-min-height-100vh g-flex-centered g-bg-lightblue-radialgradient-circle">
        <div className="container g-py-100">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-lg-8">
              <div className="u-shadow-v24 g-bg-white rounded g-py-40 g-px-30">
                <header className="text-center mb-4">
                  <h2 className="h2 text-uppercase g-font-weight-300">
                    Forgot password confirmation!
                  </h2>
                </header>
                <p className="lead g-px-100--sm g-mb-20">
                  Please check your email (including junk folder) for the reset
                  password link.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ForgotPasswordConfirmation;
