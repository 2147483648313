import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

interface IProps {
  setFiles: (files: object[]) => void;
}

const PhotoWidgetDropzone: React.FC<IProps> = ({ setFiles }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file: object) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, [setFiles]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className="row">
      <input {...getInputProps()} />
      <div className="col-12 g-mb-30">
        <article className="g-brd-around g-brd-gray-light-v4 text-center rounded g-py-80 g-px-40">
          <h3 className="h4 g-font-weight-300 g-my-20">
            {isDragActive ? (
              <label className="u-link-v5 g-color-teal">
                Drop the files here ...
              </label>
            ) : (
              <label className="u-link-v5 g-color-main">
                Drag and drop some files here, or click to select files
              </label>
            )}
          </h3>
        </article>
      </div>
    </div>
  );
};

export default PhotoWidgetDropzone;
