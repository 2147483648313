import React, { Fragment } from "react";
import { FieldRenderProps } from "react-final-form";

interface IProps extends FieldRenderProps<string, HTMLElement> {}

const TextAreaInput: React.FC<IProps> = ({
  header,
  input,
  rows,
  placeholder,
  className,
  errorClassName,
  meta: { touched, error },
}) => {
  return (
    <Fragment>
      <div
        className={
          touched && error
            ? "form-group g-mb-25 u-has-error-v1"
            : "form-group g-mb-25"
        }
      >
        <label>{header}</label>
        <textarea
          {...input}
          placeholder={placeholder}
          rows={rows}
          className={className}
        />
        {touched && error && <small className={errorClassName}>{error}</small>}
      </div>
    </Fragment>
  );
};

export default TextAreaInput;
