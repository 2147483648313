import React, { Fragment } from "react";
import { Spinner } from "react-bootstrap";

export const LoadingComponent: React.FC = () => {
  return (
    <Fragment>
      <section className="g-min-height-100vh g-flex-centered g-bg-lightblue-radialgradient-circle">
        <div className="container g-py-100">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-lg-12">
              <div className="u-shadow-v24 g-bg-white rounded g-py-40 g-px-30">
                <header className="text-center mb-4">
                  <h2 className="h2 g-color-black g-font-weight-600">
                    <Spinner
                      animation="border"
                      role="status"
                      className="my-auto"
                    >
                      <span className="sr-only">Loading....</span>
                    </Spinner>
                  </h2>
                </header>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
