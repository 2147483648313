import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { IProfile } from "../../app/models/profile";

interface IProps {
  profile: IProfile;
}

const ProfileCard: React.FC<IProps> = ({ profile }) => {
  return (
    <Fragment>       
        <div className="col-sm-6 col-lg-3 g-mb-30">
          <div className="u-shadow-v11 u-shadow-v21--hover text-center g-transition-0_3">
            <div className="g-bg-white g-pa-20">
              <div className="g-width-130 g-height-130 mx-auto mb-4">
                <img
                  className="img-fluid g-brd-around g-brd-3 g-brd-gray-light-v3 rounded-circle"
                  src={profile.image || "/assets/images/user.png"}
                  alt="User Description"
                />
              </div>
              <h3 className="h5">
                <Link className="g-color-black" to={`/profile/${profile.username}`}>
                  {profile.displayName}
                </Link>
              </h3>
            </div>
            <div className="g-bg-gray-light-v5 g-px-20 g-py-10">
              <span className="d-block">{profile.followersCount} Followers</span>
              <span className="d-block g-color-gray-dark-v5 g-font-size-13">{profile.followingCount} Followings</span>
            </div>
          </div>
        </div>
    </Fragment>   
  );
};

export default ProfileCard;
