import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { LoadingComponent } from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import ActivityChat from "./ActivityChat";
import ActivityHeader from "./ActivityHeader";
import ActivityInfo from "./ActivityInfo";
import ActivitySidebar from "./ActivitySidebar";

interface DetailParams {
  id: string;
}

const ActivityDetails: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history
}) => {

  const rootStore = useContext(RootStoreContext);
  const { activity, loadActivity, loadingInitial } = rootStore.activityStore;

  useEffect(() => {
    loadActivity(match.params.id);
  }, [loadActivity, match.params.id, history]);

  if (loadingInitial) return <LoadingComponent></LoadingComponent>;

  if (!activity) {
    return <h2>Activity not found</h2>
  }

  return (
    <Fragment>
      <section className="g-py-100">
        <div className="container">
          <div className="row g-mb-30">
            <div className="col-md-9">
              <ActivityHeader activity={activity} />
              <ActivityInfo activity={activity} />
              <hr className="g-my-20" />
              <ActivityChat />
            </div>
            <div className="col-md-3">
              <ActivitySidebar attendees={activity.attendees} />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default observer(ActivityDetails);
