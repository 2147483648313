import React from "react";
import ProfileActivities from "./ProfileActivities";
import ProfileDescription from "./ProfileDescription";
import ProfileFollowers from "./ProfileFollowers";
import ProfileFollowings from "./ProfileFollowings";
import ProfilePhotos from "./ProfilePhotos";

const ProfileContent = () => {
  return (
    <div className="col-lg-12">
      <div className="g-brd-around g-brd-gray-light-v4 g-bg-white g-pa-20 g-mb-40">
        
        <ProfileDescription />
        
        <ProfilePhotos />
         
        <ProfileActivities />

        <ProfileFollowers />

        <ProfileFollowings />

      </div>
    </div>
  );
};

export default ProfileContent;
