import { observer } from "mobx-react-lite";
import React from "react";
import { Spinner } from "react-bootstrap";
import { IProfile } from "../../app/models/profile";

interface IProps {
  profile: IProfile;
  isCurrentUser: boolean;
  loading: boolean;
  follow: (username: string) => void;
  unfollow: (username: string) => void;
}

const ProfileHeader: React.FC<IProps> = ({
  profile,
  isCurrentUser,
  loading,
  follow,
  unfollow,
}) => {

  return (
    <div className="col-lg-12">
      <div className="g-brd-around g-brd-gray-light-v4 g-bg-white g-pa-20 g-mt-40">
        <div className="row">
          <div className="col-lg-4 g-mb-40 g-mb-0--lg">
            <div className="g-mb-20">
              <img
                className="img-fluid w-100"
                src={profile?.image || "/assets/images/user.png"}
                alt="User Description"
              />
            </div>
            {!isCurrentUser && (
              <button
                className="btn btn-block u-btn-outline-teal g-rounded-50 g-py-12 g-mb-10"
                onClick={
                  profile.following
                    ? () => unfollow(profile.username)
                    : () => follow(profile.username)
                }
              >
                <i className="icon-user-follow g-pos-rel g-top-1 g-mr-5"></i>{" "}
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                )}
                {!loading && (profile.following ? "Unfollow me" : "Follow me")}
              </button>
            )}
          </div>
          <div className="col-lg-8">
            <div className="d-flex align-items-center justify-content-sm-between g-mb-5">
              <h2 className="g-font-weight-300 g-mr-10">
                {profile?.displayName}
              </h2>
              <ul className="list-inline mb-0">
                {profile?.facebook != null && (
                  <li className="list-inline-item g-mx-2">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="u-icon-v1 u-icon-size--sm u-icon-slide-up--hover g-color-gray-light-v1 g-bg-gray-light-v5 g-color-gray-light-v1--hover rounded-circle"
                      href={profile.facebook}
                    >
                      <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-facebook"></i>
                      <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-facebook"></i>
                    </a>
                  </li>
                )}
                {profile?.twitter != null && (
                  <li className="list-inline-item g-mx-2">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="u-icon-v1 u-icon-size--sm u-icon-slide-up--hover g-color-gray-light-v1 g-bg-gray-light-v5 g-color-gray-light-v1--hover rounded-circle"
                      href={profile.twitter}
                    >
                      <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-twitter"></i>
                      <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-twitter"></i>
                    </a>
                  </li>
                )}
                {profile?.instagram != null && (
                  <li className="list-inline-item g-mx-2">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="u-icon-v1 u-icon-size--sm u-icon-slide-up--hover g-color-gray-light-v1 g-bg-gray-light-v5 g-color-gray-light-v1--hover rounded-circle"
                      href={profile.instagram}
                    >
                      <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-instagram"></i>
                      <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-instagram"></i>
                    </a>
                  </li>
                )}
                {profile?.linkedIn != null && (
                  <li className="list-inline-item g-mx-2">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="u-icon-v1 u-icon-size--sm u-icon-slide-up--hover g-color-gray-light-v1 g-bg-gray-light-v5 g-color-gray-light-v1--hover rounded-circle"
                      href={profile.linkedIn}
                    >
                      <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-linkedin"></i>
                      <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-linkedin"></i>
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <ul className="list-inline g-font-weight-300">
              <li className="list-inline-item g-mr-20">
                {profile?.city != null && profile?.country != null && (
                  <i className="icon-location-pin g-pos-rel g-top-1 g-color-gray-dark-v5 g-mr-5"></i>
                )}{" "}
                {profile?.city}
                {profile?.city != null && <span>, </span>}
                {profile?.country}
              </li>
            </ul>

            <hr className="g-brd-gray-light-v4 g-my-20" />

            <p className="lead g-line-height-1_8">{profile.bio}</p>

            <hr className="g-brd-gray-light-v4 g-my-20" />
            <div className="row text-center text-uppercase">
              <div className="col-md-6 col-lg-6 g-mb-30">
                <div className="g-bg-white g-rounded-4 g-brd-2 g-brd-gray-light-v3 g-pa-20">
                  <div className="js-counter h1 g-font-weight-300 g-mb-5">
                    {profile.followersCount}
                  </div>
                  <h4 className="g-font-weight-300">Followers</h4>
                </div>
              </div>

              <div className="col-md-6 col-lg-6 g-mb-30">
                <div className="g-bg-white g-rounded-4 g-brd-2 g-brd-gray-light-v3 g-pa-20">
                  <div className="js-counter h1 g-font-weight-300 g-mb-5">
                    {profile.followingCount}
                  </div>
                  <h4 className="g-font-weight-300">Following</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ProfileHeader);
