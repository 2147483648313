import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { RootStoreContext } from "../../app/stores/rootStore";

export const Menu: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { user, logout, isLoggedIn } = rootStore.userStore;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  if(!isLoggedIn) {
    return (<Fragment></Fragment>);
  }

  return (
    <Fragment>
      <header
        className="u-header u-header--sticky-top u-header--toggle-section u-header--change-appearance"
        data-header-fix-moment="300"
      >
        <div
          className="u-header__section u-header__section--dark g-bg-teal g-transition-0_3 g-py-10"
          data-header-fix-moment-exclude="g-py-10"
        >
          <Navbar className="navbar navbar-expand-lg">
            <div className="container">
              <button
                className="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-3 g-right-0"
                type="button"
                aria-label="Toggle navigation"
                aria-expanded="false"
                aria-controls="navBar"
                data-toggle="collapse"
                data-target="#navBar"
              >
                <span className="hamburger hamburger--slider">
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </span>
              </button>

              {/* <NavbarBrand href="/"> */}
                <NavLink className="navbar-brand" exact to="/">
                  <img
                    src="/assets/images/logo.png"
                    alt="logo"
                    style={{ maxHeight: 50 }}
                  />
                </NavLink>
                Reactivities
              {/* </NavbarBrand> */}
              <NavbarToggler onClick={toggle} />
              <Collapse
                isOpen={isOpen}
                navbar
                className="collapse navbar-collapse align-items-center flex-sm-row g-pt-10 g-pt-5--lg g-mr-40--lg"
              >
                
                  <ul className="navbar-nav text-uppercase g-font-weight-600 ml-auto">
                    <li className="nav-item g-mx-20--lg">
                      <NavLink className="nav-link px-0" exact to="/">
                        Home
                      </NavLink>
                    </li>

                    <li className="nav-item g-mx-20--lg align-middle">
                      <NavLink className="nav-link px-0" exact to="/activities">
                        Activities
                      </NavLink>
                    </li>
                    <UncontrolledDropdown
                      nav
                      className="nav-item dropdown g-mx-20--lg show"
                    >
                      <DropdownToggle nav>
                        <img
                          className="g-width-30 g-width-30--md g-height-30 g-height-30--md rounded-circle g-mr-10--sm"
                          src={user?.image || "/assets/images/user.png"}
                          alt="User description"
                        />
                        <label className="g-color-white g-font-weight-300 text-uppercase">
                          {user?.displayName}
                        </label>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>
                          <NavLink
                            className="nav-link px-0 g-color-black g-font-weight-300 text-uppercase"
                            exact
                            to={`/profile/${user?.username}`}
                          >
                            My Profile
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem onClick={logout}>
                          <NavLink
                            className="nav-link px-0 g-color-black g-font-weight-300 text-uppercase"
                            exact
                            to="/"
                          >
                            Logout
                          </NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </ul>
                
              </Collapse>
              <div className="d-inline-block g-hidden-md-down g-pos-rel g-valign-middle g-pl-30 g-pl-0--lg">
                <NavLink
                  className="btn u-btn-outline-white g-brd-2 g-color-white g-bg-white-opacity-0_1 g-color-black--hover g-bg-white--hover g-font-weight-600 text-uppercase g-rounded-50 g-px-30 g-py-15"
                  to="/createActivity"
                >
                  Create Activity
                </NavLink>
              </div>
            </div>
          </Navbar>
        </div>
      </header>
    </Fragment>
  );
};

export default observer(Menu);
