import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { Spinner } from "react-bootstrap";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import TextAreaInput from "../../app/common/form/TextAreaInput";
import TextInput from "../../app/common/form/TextInput";
import { IProfile } from "../../app/models/profile";

const validate = combineValidators({
  displayName: isRequired("displayName"),
});

interface IProps {
  updateProfile: (profile: Partial<IProfile>) => void;
  profile: IProfile;
}

const ProfileEditForm: React.FC<IProps> = ({ updateProfile, profile }) => {
  return (
    <Fragment>
      <div className="row g-mb-30">
        <div className="col-10">
          <FinalForm
            onSubmit={updateProfile}
            validate={validate}
            initialValues={profile!}
            render={({ handleSubmit, invalid, pristine, submitting }) => (
              <form
                onSubmit={handleSubmit}
                className="g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-30"
              >
                <Field
                  component={TextInput}
                  header="Display Name"
                  inputType="text"
                  className="form-control rounded-0 form-control-md"
                  errorClassName="form-control-feedback"
                  placeholder="Enter display name"
                  name="displayName"
                  value={profile!.displayName}
                />
                <Field
                  component={TextAreaInput}
                  header="Biography"
                  className="form-control rounded-0 form-control-md"
                  errorClassName="form-control-feedback"
                  placeholder="Enter biography"
                  rows="5"
                  name="bio"
                  value={profile!.bio}
                    />
                <Field
                    component={TextInput}
                    header="City"
                    inputType="text"
                    className="form-control rounded-0 form-control-md"
                    errorClassName="form-control-feedback"
                    placeholder="Enter city"
                    name="city"
                    value={profile!.city}
                    />
                <Field
                    component={TextInput}
                    header="Country"
                    inputType="text"
                    className="form-control rounded-0 form-control-md"
                    errorClassName="form-control-feedback"
                    placeholder="Enter country"
                    name="country"
                    value={profile!.country}
                    />
                <Field
                    component={TextInput}
                    header="Facebook"
                    inputType="text"
                    className="form-control rounded-0 form-control-md"
                    errorClassName="form-control-feedback"
                    placeholder="Enter facebook"
                    name="facebook"
                    value={profile!.facebook}
                    />
                <Field
                    component={TextInput}
                    header="Twitter"
                    inputType="text"
                    className="form-control rounded-0 form-control-md"
                    errorClassName="form-control-feedback"
                    placeholder="Enter twitter"
                    name="twitter"
                    value={profile!.twitter}
                    />
                <Field
                    component={TextInput}
                    header="Instagram"
                    inputType="text"
                    className="form-control rounded-0 form-control-md"
                    errorClassName="form-control-feedback"
                    placeholder="Enter instagram"
                    name="instagram"
                    value={profile!.instagram}
                    />
                <Field
                    component={TextInput}
                    header="LinkedIn"
                    inputType="text"
                    className="form-control rounded-0 form-control-md"
                    errorClassName="form-control-feedback"
                    placeholder="Enter linkedIn"
                    name="linkedIn"
                    value={profile!.linkedIn}
                />
                <button
                  className="btn btn-md u-btn-teal g-mr-10 g-mb-15"
                  disabled={submitting || invalid || pristine}
                >
                  {submitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  )}
                  {!submitting && <span>Save Changes</span>}
                </button>
              </form>
            )}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default observer(ProfileEditForm);
