import React, { useEffect, useContext, Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { IUserActivity } from "../../app/models/profile";
import { format } from "date-fns";
import { RootStoreContext } from "../../app/stores/rootStore";

const ProfileEvents = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadUserActivities,
    profile,
    userActivities,
  } = rootStore.profileStore!;

  useEffect(() => {
    loadUserActivities(profile!.username);
  }, [loadUserActivities, profile]);

  const handleTabChange = (
    predicate: string
  ) => {
    loadUserActivities(profile!.username, predicate);
  };

  return (
    <Fragment>
      <div className="card border-0 rounded-0 g-mb-40">
        <div className="card-header d-flex align-items-center justify-content-between g-bg-gray-light-v5 border-0 g-mb-15">
          <h3 className="h6 mb-0">
            <i className="icon-event g-pos-rel g-top-1 g-mr-5"></i> Activities
          </h3>
        </div>
        <div className="card-block">
          <div className="row">
            <div className="col-lg-12">
              <ul className="u-list-inline mb-0">
                <li className="list-inline-item g-mb-10">
                  <button
                    className="btn btn-sm u-btn-outline-darkgray g-rounded-50 g-mr-10 g-mb-15"
                    onClick={() => handleTabChange("future")}
                  >
                    Future Events
                  </button>
                </li>
                <li className="list-inline-item g-mb-10">
                  <button
                   className="btn btn-sm u-btn-outline-darkgray g-rounded-50 g-mr-10 g-mb-15"
                   onClick={() => handleTabChange("past")}
                  >
                    Past Events
                  </button>
                </li>
                <li className="list-inline-item g-mb-10">
                <button
                   className="btn btn-sm u-btn-outline-darkgray g-rounded-50 g-mr-10 g-mb-15"
                   onClick={() => handleTabChange("hosting")}
                  >
                    Hosting
                  </button>
                </li>
              </ul>
            </div>
            {userActivities.map((activity: IUserActivity) => (
              <div className="col-lg-3 col-sm-6 g-mb-30" key={activity.id}>
                <Link to={`/activities/${activity.id}`}>
                  <figure className="text-center">
                    <img
                      className="w-100 g-mb-20"
                      src={`/assets/images/categoryImages/${activity.category}.jpg`}
                      alt="Activity Description"
                    />
                    <div className="g-mb-10">
                      <h4 className="h5 g-color-black g-mb-5">
                        {activity.title}
                      </h4>
                      <em className="d-block g-font-style-normal g-font-size-11 text-uppercase g-color-primary">
                        {format(new Date(activity.date), "do LLL")}
                      </em>
                      <em className="d-block g-font-style-normal g-font-size-11 text-uppercase g-color-primary">
                        {format(new Date(activity.date), "h:mm a")}
                      </em>
                    </div>
                  </figure>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <Tab.Pane loading={loadingActivities}>
      <Grid>
        <Grid.Column width={16}>
          <Header floated='left' icon='calendar' content={'Activities'} />
        </Grid.Column>
        <Grid.Column width={16}>
          <Tab
            panes={panes}
            menu={{ secondary: true, pointing: true }}
            onTabChange={(e, data) => handleTabChange(e, data)}
          />
          <br />
          <Card.Group itemsPerRow={4}>
            {userActivities.map((activity: IUserActivity) => (
              <Card
                as={Link}
                to={`/activities/${activity.id}`}
                key={activity.id}
              >
                <Image
                  src={`/assets/categoryImages/${activity.category}.jpg`}
                  style={{ minHeight: 100, objectFit: 'cover' }}
                />
                <Card.Content>
                  <Card.Header textAlign='center'>{activity.title}</Card.Header>
                  <Card.Meta textAlign='center'>
                    <div>{format(new Date(activity.date), 'do LLL')}</div>
                    <div>{format(new Date(activity.date), 'h:mm a')}</div>
                  </Card.Meta>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        </Grid.Column>
      </Grid>
    </Tab.Pane> */}
    </Fragment>
  );
};

export default observer(ProfileEvents);
