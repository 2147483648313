import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import PhotoUploadWidget from "../../app/common/photoUpload/PhotoUploadWidget";
import { RootStoreContext } from "../../app/stores/rootStore";

const ProfilePhotos = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    profile,
    isCurrentUser,
    uploadPhoto,
    uploadingPhoto,
    setMainPhoto,
    deletePhoto,
    loading,
  } = rootStore.profileStore;
  const [addPhotoMode, setAddPhotoMode] = useState(false);
  const [target, setTarget] = useState<string | undefined>(undefined);
  const [deleteTarget, setDeleteTarget] = useState<string | undefined>(
    undefined
  );

  const handleUploadImage = (photo: Blob) => {
    uploadPhoto(photo).then(() => setAddPhotoMode(false));
  };

  return (
    <Fragment>
      <div className="card border-0 rounded-0 g-mb-40">
        <div className="card-header d-flex align-items-center justify-content-between g-bg-gray-light-v5 border-0 g-mb-15">
          <h3 className="h6 mb-0">
            <i className="icon-picture g-pos-rel g-top-1 g-mr-5"></i> Photos
          </h3>
        </div>
        <div className="card-block">
          <div className="text-right g-mb-50">
            {isCurrentUser && (
              <button
                onClick={() => setAddPhotoMode(!addPhotoMode)}
                className="rounded-0 btn btn-xxl u-btn-teal g-font-size-11 g-font-weight-700 text-uppercase g-px-10"
              >
                {addPhotoMode ? "Cancel" : "Add Photo"}
              </button>
            )}
          </div>
          <div className="row">
            {addPhotoMode ? (
              <div className="col-12 g-mb-40">
                <PhotoUploadWidget
                  uploadPhoto={handleUploadImage}
                  loading={uploadingPhoto}
                />
              </div>
            ) : (
              <Fragment>
                {profile &&
                  profile.photos.map((photo) => (
                    <div className="col-md-3 g-mb-30" key={photo.id}>
                      <figure className="u-info-v7-1 g-brd-around g-brd-gray-light-v4 g-bg-white g-rounded-4 text-center">
                        <div className="g-py-40 g-px-20">
                          <img
                            className="g-width-120 g-height-120"
                            src={photo.url}
                            alt="Profile Description"
                          />
                        </div>
                        <div className="d-block g-mb-30">
                          {isCurrentUser &&
                            (photo.isMain ? (
                              <span className="u-info-v7-1__item-child-v2 g-color-gray-dark-v4 g-bg-gray-light-v5 g-font-size-default g-rounded-25 g-py-5 g-px-20 g-mr-3">
                                Main Photo
                              </span>
                            ) : (
                              <span className="g-py-5 g-px-20 g-mr-3"></span>
                            ))}
                        </div>
                        <hr className="g-brd-gray-light-v4 g-my-0" />
                        {isCurrentUser && !photo.isMain && (
                          <ul className="row list-inline g-py-20 g-ma-0">
                            <li className="col g-brd-right g-brd-gray-light-v4">
                              <button
                                name={photo.id}
                                className="btn btn-sm btn-block u-btn-teal g-mr-10 g-mb-10"
                                onClick={(e) => {
                                  setMainPhoto(photo);
                                  setTarget(e.currentTarget.name);
                                }}
                                disabled={photo.isMain}
                              >
                                {loading && target === photo.id && (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></Spinner>
                                )}
                                {target !== photo.id && <span>Main</span>}
                              </button>
                            </li>
                            <li className="col g-brd-gray-light-v4">
                              <button
                                className="btn btn-sm btn-block u-btn-red g-mr-10 g-mb-10"
                                name={photo.id}
                                disabled={photo.isMain}
                                onClick={(e) => {
                                  deletePhoto(photo);
                                  setDeleteTarget(e.currentTarget.name);
                                }}
                              >
                                {loading && deleteTarget === photo.id && (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></Spinner>
                                )}
                                {deleteTarget !== photo.id && (
                                  <span>Delete</span>
                                )}
                              </button>
                            </li>
                          </ul>
                        )}
                      </figure>
                    </div>
                  ))}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default observer(ProfilePhotos);
