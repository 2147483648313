import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import "react-toastify/dist/ReactToastify.min.css";
import 'react-widgets/dist/css/react-widgets.css';

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./app/layout/unify-core.css";
import "./app/layout/unify-components.css";
import "./app/layout/unify-globals.css";
import "./app/layout/hamburgers.css";
import "./app/layout/icon-line/css/simple-line-icons.css";
import "./app/layout/icon-line-pro/style.css";
import "./app/layout/icon-awesome/css/font-awesome.min.css"
import "./app/layout/custom.css";

import App from "./app/layout/App";
import reportWebVitals from "./reportWebVitals";
import ScrollToTop from "./app/layout/ScrollToTop";
import dateFnsLocalizer from 'react-widgets-date-fns';

dateFnsLocalizer();

export const history = createBrowserHistory();

ReactDOM.render(
  <React.Fragment>
    <Router history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Router>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
