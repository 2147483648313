import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IActivity } from "../../../app/models/activity";
import { RootStoreContext } from "../../../app/stores/rootStore";

const ActivityHeader: React.FC<{ activity: IActivity }> = ({ activity }) => {
  const rootStore = useContext(RootStoreContext);
  const { attendActivity, cancelAttendance, loading } = rootStore.activityStore;
  const host = activity.attendees.filter((x) => x.isHost)[0];
  return (
    <Fragment>
      <div id="shortcode4">
        <div className="shortcode-html">
          <div className="row">
            <div className="col-lg-12 g-mb-30">
              <article className="u-block-hover">
                <figure className="u-bg-overlay g-bg-black-gradient-opacity-v1--after">
                  <img
                    className="img-fluid w-100 u-block-hover__main--zoom-v1"
                    src={`/assets/images/categoryImages/${
                      activity!.category
                    }.jpg`}
                    alt="Category"
                  />
                </figure>
                <span className="g-hidden-xs-down g-pos-abs g-top-20 g-left-20">
                  <a className="btn btn-sm u-btn-teal rounded-0" href="/">
                    {activity.venue}, {activity.city}
                  </a>
                </span>
                <div className="g-pos-abs g-bottom-20 g-left-20 g-right-20">
                  <div className="media g-mb-15">
                    <div className="d-flex mr-3">
                    <img
                    className="g-brd-around g-brd-gray-light-v3 g-pa-2 g-width-60 g-height-60 rounded-circle"
                    src={host.image || "/assets/images/user.png"}
                    alt="User"
                  />
                    </div>

                    <div className="media-body align-self-center g-color-white">
                      <p className="mb-0">Hosted by <Link to={`/profile/${host.username}`}>{host.displayName}</Link></p>
                    </div>
                  </div>

                  <h3 className="g-font-weight-600 g-mb-15">
                    <a className="g-color-white g-color-white--hover" href="/">
                      {activity.title}
                    </a>
                  </h3>

                  <ul className="u-list-inline g-font-size-12 g-color-white">
                    <li className="list-inline-item">
                      <i className="icon-clock g-pos-rel g-top-1 g-mr-2"></i>
                      {format(activity.date!, "eeee do MMMM")}
                    </li>
                    <li className="list-inline-item">/</li>
                    <li className="list-inline-item">
                      <i className="icon-speech g-pos-rel g-top-1 g-mr-2"></i>
                      52
                    </li>
                    <li className="list-inline-item">/</li>
                    <li className="list-inline-item">
                      <i className="icon-share g-pos-rel g-top-1 g-mr-2"></i>
                      26
                    </li>
                  </ul>
                </div>
              </article>
            </div>
          </div>
          <div className="row g-mb-10">
            <div className="col-sm-12">
              <ul className="u-list-inline d-flex flex-wrap g-mr-minus-20 mb-0">
                <li className="list-inline-item g-mr-20 g-mb-20">
                  {activity.isHost ? (
                    <Link
                      to={`/manage/${activity.id}`}
                      className="btn btn-md u-btn-outline-darkgray g-mb-15"
                    >
                      Manage Event
                    </Link>
                  ) : activity.isGoing ? (
                    <button
                      className="btn btn-md u-btn-outline-red g-mb-15"
                      onClick={cancelAttendance}
                    >
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        ></Spinner>
                      )}
                      {!loading && <span>Cancel Attendance</span>}
                    </button>
                  ) : (
                    <button
                      className="btn btn-md u-btn-outline-teal g-mb-15"
                      onClick={attendActivity}
                    >
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        ></Spinner>
                      )}
                      {!loading && <span>Join Activity</span>}
                    </button>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default observer(ActivityHeader);
