import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { RootStoreContext } from "../../app/stores/rootStore";
import ProfileCard from "./ProfileCard";

const ProfileFollowings = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    profile,
    followings,
    loadFollowings
  } = rootStore.profileStore;

  useEffect(() => {
    loadFollowings("following");
  }, [loadFollowings]);

  return (
    <Fragment>
      <div className="card border-0 rounded-0 g-mb-40">
        <div className="card-header d-flex align-items-center justify-content-between g-bg-gray-light-v5 border-0 g-mb-15">
          <h3 className="h6 mb-0">
            <i className="icon-user-follow g-pos-rel g-top-1 g-mr-5"></i> Following
          </h3>
        </div>
        <div className="card-block">
          <div className="row g-mb-70">
            {followings.map((profile) => (
              <ProfileCard key={profile.username} profile={profile} />
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default observer(ProfileFollowings);
