import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { RootStoreContext } from "../../app/stores/rootStore";
import config from "../../config";

let environment = "";

if (process.env.NODE_ENV === "development") {
  environment = "Local Development";
} else {
  environment = config.environment;
}

export const HomePage = () => {
  const token = window.localStorage.getItem("jwt");
  const rootStore = useContext(RootStoreContext);
  const { isLoggedIn, user } = rootStore.userStore;
  return (
    <Fragment>
      <main
        className="g-min-height-100vh g-flex-centered g-bg-size-cover g-bg-cover g-bg-bluegray-opacity-0_3--after g-pa-15"
        style={{ backgroundImage: `url(/assets/images/img-bg-10.jpg)` }}
      >
        <div className="text-center g-max-width-700 g-flex-centered-item g-z-index-1 g-color-white">
          <h1 className="display-3 g-mb-30">Aventify Is Coming Soon</h1>
          <p className="g-font-size-22 g-mb-50">
            Avent = the day before an event, especially a holiday.
          </p>
          <p className="g-font-size-22 g-mb-50">
            The site is under construction
          </p>
        </div>
      </main>
      {/* <section className="g-pos-rel">
        <div className="g-bg-teal g-pos-rel g-z-index-1 g-overflow-hidden g-pb-120 g-pb-150--md">
          <div className="container text-center g-pos-rel g-z-index-1 g-pt-150 g-pb-70--md g-pb-200--lg">
            <h2 className="h5 d-inline-block g-brd-bottom g-brd-2 g-brd-white g-color-white g-font-weight-600 text-uppercase g-pb-5 g-mb-30">
              Welcome to
            </h2>
            <h3 className="g-color-white g-font-weight-700 g-font-size-40 g-font-size-55--md text-uppercase g-letter-spacing-5 g-mb-20">
              Aventify
            </h3>
            {isLoggedIn && user && token ? (
              <Fragment>
                <Link
                  className="btn u-btn-outline-white g-brd-2 g-color-white g-bg-white-opacity-0_1 g-color-black--hover g-bg-white--hover g-font-weight-600 text-uppercase g-rounded-50 g-px-30 g-py-15"
                  to="/activities"
                >
                  Go to your activities {user.displayName}
                </Link>
              </Fragment>
            ) : (
              <Fragment>
                <Link
                  className="btn u-btn-outline-white g-brd-2 g-color-white g-bg-white-opacity-0_1 g-color-black--hover g-bg-white--hover g-font-weight-600 text-uppercase g-rounded-50 g-px-30 g-py-15"
                  to="/login"
                >
                  Go to Login
                </Link>

                <Link
                  className="btn btn-lg u-btn-outline-black g-font-weight-600 g-font-size-13 text-uppercase g-rounded-50 mx-2 g-px-25 g-py-15 g-ml-15"
                  to="/register"
                >
                  Or Register
                </Link>
              </Fragment>
            )}
          </div>
        </div>
      </section>
      <section className="g-bg-gray-light-v5">
        <div className="container g-py-100">
          <div className="row justify-content-center g-mb-110">
            <div className="col-lg-8">
              <div className="text-center">
                <span className="d-block g-color-primary g-font-size-17 text-uppercase mb-5">
                  We create
                </span>
                <h2 className="h1 g-color-black g-font-weight-600 mb-5">
                  Great events for everyone
                </h2>
                <p className="lead mb-5">
                  We are a creative event company focusing on culture, sports,
                  museums, food &amp; drinks. Somewhere between sophistication
                  and simplicity.
                </p>
                <p className="lead mb-5">
                  Avent = the day before an event, especially a holiday.
                </p>
                <a
                  className="btn btn-xl u-btn-outline-black g-font-weight-600 g-font-size-default g-px-35"
                  href="/register"
                >
                  Register
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="row no-gutters u-shadow-v21 g-mt-minus-100">
          <div className="col-sm-6 col-md-4 g-brd-right--md g-brd-gray-light-v4">
            <div className="g-bg-white text-center g-py-100">
              <span className="u-icon-v1 u-icon-size--xl g-color-primary mb-3">
                <i className="icon-real-estate-027 u-line-icon-pro"></i>
              </span>
              <h4 className="h5 g-font-weight-600 g-mb-5">Address</h4>
              <span className="d-block">
                Ingenjörsvägen 18, Stockholm, Sweden
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 g-hidden-xs-down g-brd-right--md g-brd-gray-light-v4">
            <div className="g-bg-white text-center g-py-100">
              <span className="u-icon-v1 u-icon-size--xl g-color-primary mb-3">
                <i className="icon-electronics-005 u-line-icon-pro"></i>
              </span>
              <h4 className="h5 g-font-weight-600 g-mb-5">Phone Number</h4>
              <span className="d-block">+4670 570 65 11</span>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 g-hidden-sm-down">
            <div className="g-bg-white text-center g-py-100">
              <span className="u-icon-v1 u-icon-size--xl g-color-primary mb-3">
                <i className="icon-communication-062 u-line-icon-pro"></i>
              </span>
              <h4 className="h5 g-font-weight-600 g-mb-5">Email</h4>
              <span className="d-block">mattegol.egnell@gmail.com</span>
            </div>
          </div>
        </div>
      </section>
      <div className="container text-center g-py-100">
        <h2 className="h1 g-font-weight-300 mb-3">
          Join <span className="g-color-primary">Us</span>
        </h2>
        <p className="g-color-gray g-font-weight-500 text-uppercase g-letter-spacing-1 g-mb-50">
          Sign up to our site and start the fun!
        </p>
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <a
              className="btn btn-xl u-btn-primary g-font-weight-500 g-font-size-default g-rounded-4 g-px-35"
              href="/register"
            >
              Sign Up
            </a>
          </div>
        </div>
      </div>
      <footer className="g-bg-gray-dark-v1 g-color-white-opacity-0_8 g-py-20">
        <div className="container">
          <div className="row">
            <div className="col-md-8 text-center text-md-left g-mb-10 g-mb-0--md">
              <div className="d-lg-flex">
                <small className="d-block g-font-size-default g-mr-10 g-mb-10 g-mb-0--md">
                  2021 © All Rights Reserved.
                </small>
              </div>
            </div>
            <div className="col-md-4 align-self-center">
              <div className="list-inline text-center text-md-right mb-0">
                <small className="d-block g-font-size-default g-mr-10 g-mb-10 g-mb-0--md">
                  {environment}
                </small>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
    </Fragment>
  );
};
