import { observer } from "mobx-react-lite";
import React from "react";
import { Spinner } from "react-bootstrap";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

interface IProps {
  fbCallback: (response: any) => void;
  loading: boolean;
}

const SocialLogin: React.FC<IProps> = ({ fbCallback, loading }) => {
  return (
    <FacebookLogin
      appId="124528269549413"
      fields="name,email,picture"
      callback={fbCallback}
      render={(renderProps: any) => (
        <button
          onClick={renderProps.onClick}
          className="btn btn-block u-btn-facebook rounded g-px-30 g-py-13 mr-1"
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            ></Spinner>
          )}
          {!loading && <span>Facebook</span>}
        </button>
      )}
    />
  );
};

export default observer(SocialLogin);
