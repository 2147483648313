import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { DateTimePicker } from "react-widgets";
import { RootStoreContext } from "../../../app/stores/rootStore";

const ActivityFilters = () => {
  const rootStore = useContext(RootStoreContext);
  const { predicate, setPredicate } = rootStore.activityStore;
  return (
    <Fragment>
      <div className="col-md-4 g-mt-30">
        <h5>Filters</h5>
        <ul className="u-list-inline mb-0">
          <li className="list-inline-item g-mb-10">
            <button
              className={
                predicate.size === 0
                  ? "btn u-btn-darkgray g-rounded-50 g-mr-10 g-mb-15"
                  : "btn u-btn-outline-darkgray g-rounded-50 g-mr-10 g-mb-15"
              }
              onClick={() => setPredicate('all', 'true')}
            >
              View All
            </button>
          </li>
          <li className="list-inline-item g-mb-10">
            <button
              className={
                predicate.has('isGoing')
                  ? "btn u-btn-darkgray g-rounded-50 g-mr-10 g-mb-15"
                  : "btn u-btn-outline-darkgray g-rounded-50 g-mr-10 g-mb-15"
              }
              onClick={() => setPredicate('isGoing', 'true')}
            >
              I'm Going
            </button>
          </li>
          <li className="list-inline-item g-mb-10">
            <button
              className={
                predicate.has('isHost')
                  ? "btn u-btn-darkgray g-rounded-50 g-mr-10 g-mb-15"
                  : "btn u-btn-outline-darkgray g-rounded-50 g-mr-10 g-mb-15"
              }
              onClick={() => setPredicate('isHost', 'true')}
            >
              I'm Hosting
            </button>
          </li>
        </ul>
      </div>
      <div className="col-md-5 g-mt-30"></div>
      <div className="col-md-3 g-mt-30">
        <h5>Select Date</h5>
        <DateTimePicker
          placeholder={"Select Date"}
          onKeyDown={(e) => e.preventDefault()}
          date={true}
          time={false}
          containerClassName={""}
          onChange={(date) => setPredicate('startDate', date!)}
          value={predicate.get('startDate') || new Date()}
        />
      </div>
    </Fragment>
  );
};

export default observer(ActivityFilters);
