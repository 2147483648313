import React, { Fragment } from "react";
import ContentLoader from "react-content-loader";

const ActivityListItemContentLoader = () => {
  return (
    <Fragment>
      <LoaderCard />
      <LoaderCard />
      <LoaderCard />
      <LoaderCard />
      <LoaderCard />
    </Fragment>
  );
};


const LoaderCard = () => {
  return (
    <ContentLoader
      style={{ width: "100%" }}
      backgroundColor="#dadada"
      foregroundColor="#fafafa"
    >
      <rect x="150" y="15" rx="5" ry="5" width="1000" height="120" />
      <rect x="0" y="15" rx="0" ry="0" width="120" height="120" />
    </ContentLoader>
  );
};

export default ActivityListItemContentLoader;
