import { AxiosResponse } from "axios";
import React, { Fragment } from "react";

interface IProps {
  error: AxiosResponse;
  text?: string;
}

const ErrorMessage: React.FC<IProps> = ({ error, text }) => {
  return (
    <Fragment>
      <div
        className="alert alert-danger"
        role="alert"
      >
        <h4 className="h5">{error?.statusText}</h4>
        {error.data && error.data.errors && Object.keys(error.data.errors).length > 0 && (
            <ul>
              {Object.values(error.data.errors).flat().map((err: any, i) => (
                  <li key={i}>{err}</li>
              ))}  
            </ul>
        )}
        {text && <p>{text}</p>}
      </div>
    </Fragment>
  );
};

export default ErrorMessage;
