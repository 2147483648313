import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useState } from "react";
import { RootStoreContext } from "../../app/stores/rootStore";
import ProfileEditForm from "./ProfileEditForm";

const ProfileDescription = () => {
  const rootStore = useContext(RootStoreContext);
  const { updateProfile, profile, isCurrentUser } = rootStore.profileStore;
  const [editMode, setEditMode] = useState(false);
  return (
    <Fragment>
      <div className="card border-0 rounded-0 g-mb-40">
        <div className="card-header d-flex align-items-center justify-content-between g-bg-gray-light-v5 border-0 g-mb-15">
          <h3 className="h6 mb-0">
            <i className="icon-picture g-pos-rel g-top-1 g-mr-5"></i> About{" "}
            {profile?.displayName}
          </h3>
        </div>
        <div className="card-block">
          <div className="text-right">
            {isCurrentUser && (
              <button
                onClick={() => setEditMode(!editMode)}
                className="rounded-0 btn btn-xxl u-btn-teal g-font-size-11 g-font-weight-700 text-uppercase g-px-10"
              >
                {editMode ? "Cancel" : "Edit Profile"}
              </button>
            )}
          </div>
          <div className="g-mb-50">
            {editMode ? (
              <ProfileEditForm
                updateProfile={updateProfile}
                profile={profile!}
              />
            ) : (
              <div className="col-10">
                <p>{profile!.bio}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default observer(ProfileDescription);
