import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { IAttendee } from "../../../app/models/activity";

interface IProps {
  attendees: IAttendee[];
}

const ActivitySidebar: React.FC<IProps> = ({ attendees }) => {
  return (
    <Fragment>
      <div className="g-mb-20">
        <h4 className="h4 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          {attendees.length} {attendees.length === 1 ? "Person" : "People"}{" "}
          going
        </h4>
      </div>
      <ul className="list-unstyled">
        {attendees.map((attendee) => (
          <li
            key={attendee.username}
            className="g-brd-around g-brd-gray-light-v4 g-brd-left-3 g-brd-teal-left g-rounded-3 g-pa-20 g-mb-7"
          >
            <div className="d-flex justify-content-start">
              <Link
                to={`/profile/${attendee.username}`}
                className="g-font-weight-600 g-color-black"
              >
                {attendee.displayName}
              </Link>
              {attendee.isHost && (
                <span className="u-label g-bg-orange g-rounded-20 u-label--sm g-ml-10 g-mb-10">
                  Host
                </span>
              )}
            </div>
            <div className="d-flex justify-content-between">
              <ul className="u-list-inline">
                <li className="list-inline-item mr-0">
                  <img
                    className="g-brd-around g-brd-gray-light-v3 g-pa-2 g-width-40 g-height-40 rounded-circle"
                    src={attendee.image || "/assets/images/user.png"}
                    alt="User"
                  />
                </li>
              </ul>
              <div className="align-self-center">
                {attendee.following &&
                  <span className="u-label g-bg-deeporange g-rounded-20 g-px-15 g-mr-10 g-mb-15">Following</span>
                }
                {/* <a
                  className="btn u-btn-outline-teal g-font-weight-600 g-rounded-50"
                  href="/home"
                >
                  Follow
                </a> */}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

export default observer(ActivitySidebar);
