import { action, computed, observable, makeObservable, runInAction } from "mobx";
import { history } from "../..";
import agent from "../api/agent";
import { IUser, IUserFormValues } from "../models/user";
import { RootStore } from "./rootStore";

export default class UserStore {
  refreshTokenTimeout: any;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable user: IUser | null = null;
  @observable loading = false;

  @computed get isLoggedIn() {
    return !!this.user;
  }

  @action login = async (values: IUserFormValues) => {
    try {
      const user = await agent.User.login(values);
      runInAction(() => {
        this.user = user;
      })
      this.rootStore.commonStore.setToken(user.token);
      this.startRefeshTokenTimer(user);
      history.push('/activities');
    } catch (error) {
      throw error;
    }
  };

  @action register = async (values: IUserFormValues) => {
    try {
      await agent.User.register(values);      
      history.push(`/user/registerSuccess?email=${values.email}`);
    } catch(error) {
      throw error;
    }
  }

  @action forgotPassword = async (values: IUserFormValues) => {
    try {
      await agent.User.forgotPassword(values.email);      
      history.push(`/forgotPasswordConfirmation`);
    } catch(error) {
      throw error;
    }
  }

  @action resetPassword = async (token: string, email: string, password: string) => {
    try {
      await agent.User.resetPassword(token, email, password);      
      history.push(`/resetPasswordConfirmation}`);
    } catch(error) {
      throw error;
    }
  }

  @action refreshToken = async () => {
    this.stopRefreshTokenTimer();
    try {
      const user = await agent.User.refreshToken();
      runInAction(() => {
        this.user = user;
      })
      this.rootStore.commonStore.setToken(user.token);
      this.startRefeshTokenTimer(user);
    } catch(error) {
      console.log(error);
    }
  }

  @action getUser = async () => {
    try {
      const user = await agent.User.current();
      runInAction(() => {
        this.user = user;
      });
      this.rootStore.commonStore.setToken(user.token);
      this.startRefeshTokenTimer(user);
    } catch (error) {
      console.log(error);
    }
  };

  @action logout = () => {
    this.rootStore.commonStore.setToken(null);
    this.user = null;
  }

  @action fbLogin = async (response: any) => {
    this.loading = true;
    try {
      const user = await agent.User.fbLogin(response.accessToken);
      runInAction(() => {
        this.user = user;
        this.rootStore.commonStore.setToken(user.token);
        this.startRefeshTokenTimer(user);
        this.loading = false;
      });
      history.push('/activities');
    } catch (error) {
      this.loading = false;
      throw error;
    }
  }

  private startRefeshTokenTimer(user: IUser) {
    const jwtToken = JSON.parse(atob(user.token.split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
