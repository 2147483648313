import React, { Fragment, useContext, useEffect } from "react";
import Menu from "../../features/nav/Menu";
import ActivityDashboard from "../../features/activities/dashboard/ActivityDashboard";
import { observer } from "mobx-react-lite";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { HomePage } from "../../features/home/HomePage";
import ActivityForm from "../../features/activities/form/ActivityForm";
import ActivityDetails from "../../features/activities/details/ActivityDetails";
import { NotFound } from "./NotFound";
import { ToastContainer } from "react-toastify";
import LoginForm from "../../features/user/LoginForm";
import RegisterForm from "../../features/user/RegisterForm";
import { RootStoreContext } from "../stores/rootStore";
import { LoadingComponent } from "./LoadingComponent";
import ProfilePage from "../../features/profiles/ProfilePage";
import { PrivateRoute } from "./PrivateRoute";
import RegisterSuccess from "../../features/user/RegisterSuccess";
import VerifyEmail from "../../features/user/VerifyEmail";
import ForgotPassword from "../../features/user/ForgotPassword";
import ForgotPasswordConfirmation from "../../features/user/ForgotPasswordConfirmation";
import ResetPasswordConfirmation from "../../features/user/ResetPasswordConfirmation";
import ResetPassword from "../../features/user/ResetPassword";

const App: React.FC<RouteComponentProps> = ({ location }) => {
  const rootStore = useContext(RootStoreContext);
  const { setAppLoaded, token, appLoaded } = rootStore.commonStore;
  const { getUser, isLoggedIn } = rootStore.userStore;

  useEffect(() => {
    if (token && !appLoaded) {
      getUser().finally(() => setAppLoaded());
    } else {
      setAppLoaded();
    }
  }, [getUser, setAppLoaded, token, appLoaded]);

  if (!appLoaded) {
    return <LoadingComponent />;
  }

  return (
    <Fragment>
      <ToastContainer position="bottom-right" />
      <Route exact path="/" component={HomePage} />

      <Route
        path={"/(.+)"}
        render={() => (
          <section className="g-bg-secondary">
            <Menu />
            <div className={isLoggedIn ? "container margin-top" : ""}>
              <Switch>
                <Route exact path="/login" component={LoginForm} />
                <Route exact path="/register" component={RegisterForm} />
                <Route
                  exact
                  path="/user/registerSuccess"
                  component={RegisterSuccess}
                ></Route>
                <Route
                  exact
                  path="/user/verifyEmail"
                  component={VerifyEmail}
                ></Route>
                <Route
                  exact
                  path="/forgotPassword"
                  component={ForgotPassword}
                ></Route>
                <Route
                  exact
                  path="/forgotPasswordConfirmation"
                  component={ForgotPasswordConfirmation}
                ></Route>
                <Route
                  exact
                  path="/resetPassword"
                  component={ResetPassword}
                ></Route>
                <Route
                  exact
                  path="/resetPasswordConfirmation"
                  component={ResetPasswordConfirmation}
                ></Route>
                <PrivateRoute
                  exact
                  path="/activities"
                  component={ActivityDashboard}
                />
                <PrivateRoute
                  path="/activities/:id"
                  component={ActivityDetails}
                />
                <PrivateRoute
                  key={location.key}
                  path={["/createActivity", "/manage/:id"]}
                  component={ActivityForm}
                />
                <PrivateRoute
                  path="/profile/:username"
                  component={ProfilePage}
                />

                <Route component={NotFound}></Route>
              </Switch>
            </div>
          </section>
        )}
      />
    </Fragment>
  );
};

export default withRouter(observer(App));
